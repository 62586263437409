import {tournament_api} from "../../api/tournament_api";
import React, {useState} from 'react';

function PlayerDisplay({player, tournament, matches}) {

    const [firstname, setFirstname] = useState(player.firstname);
    const [lastname, setLastname] = useState(player.lastname);
    const [level, setLevel] = useState(player.level);
    const [sex, setSex] = useState(player.sex);

    const playerMatches = matches.filter(match => match.player1_id === player.id || match.player2_id === player.id || match.player3_id === player.id || match.player4_id === player.id);
    const playerMatchesNumber = playerMatches.length;

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        const data = {
            id: player.id,
            firstname: firstname,
            lastname: lastname,
            level: level,
            sex: sex,
        }

        try {
            let result = await tournament_api.updatePlayerFromTournament(tournament, data);
            if (result === 204) {
                alert("Joueur mis à jour !");
            } else {
                alert("Erreur lors de la mise à jour du joueur !");
            }
        }
        catch (e) {
            console.log(e);
        }
        return false;
    }

    async function deletePlayer() {
        if (!window.confirm("Êtes-vous sûr de vouloir supprimer ce joueur ?")) {
            return;
        }

        try {
            let result = await tournament_api.deletePlayerFromTournament(tournament, player.id);
            if (result === 204) {
                alert("Joueur supprimé !");
            } else {
                alert("Erreur lors de la suppression du joueur !");
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="rounded bg-gray-100 p-2 w-full flex justify-between">
            <div>
                <p className="font-bold">
                    { player.firstname.charAt(0).toUpperCase() + player.firstname.slice(1) } { player.lastname.charAt(0).toUpperCase() + player.lastname.slice(1) }
                </p>
                <p>
                    Prénom :
                    <input type="text" name="firstname" id="firstname" placeholder={"Prénom"}
                        className="w-full rounded-lg border border-gray-200 p-1 pe-12 text-sm shadow-sm" value={firstname} onChange={e => setFirstname(e.target.value)} />
                </p>
                <p>
                    Nom :
                    <input type="text" name="lastname" id="lastname" placeholder={"Nom"}
                        className="w-full rounded-lg border border-gray-200 p-1 pe-12 text-sm shadow-sm" value={lastname} onChange={e => setLastname(e.target.value)} />
                </p>
                <p>
                    Niveau : 
                    <select name="level" id="level" className="ml-2" onChange={e => setLevel(e.target.value)} value={level}>
                        <option value={1}>Non classé</option>
                        <option value={2}>P12 - P11</option>
                        <option value={3}>P10 - D9</option>
                        <option value={4}>D8 - D7</option>
                        <option value={5}>R6 – N3</option>
                    </select>
                </p>
                <p>
                    Genre :
                    <select name="level" id="level" className="ml-2" onChange={e => setSex(e.target.value)} value={sex}>
                        <option value={false}>Homme</option>
                        <option value={true}>Dame</option>
                    </select>
                </p>
                <p>
                    Nombre de matchs :
                    <span className=""> {playerMatchesNumber} </span>
                    
                </p>
                {(player.double) && <p>
                    Partenaire double : { player.double_firstname.charAt(0).toUpperCase() + player.double_firstname.slice(1) } { player.double_lastname.charAt(0).toUpperCase() + player.double_lastname.slice(1) }
                </p>}
                {(player.mixed) && <p>
                    Partenaire mixte : { player.mixed_firstname.charAt(0).toUpperCase() + player.mixed_firstname.slice(1) } { player.mixed_lastname.charAt(0).toUpperCase() + player.mixed_lastname.slice(1) }
                </p>}
            </div>
            <div>
                <button onClick={handleSubmit} className="mr-3 bg-blue-400 rounded p-1">Modifier</button>
                <button onClick={deletePlayer} className="bg-red-400 rounded p-1">Supprimer</button>
            </div>
        </div>
    );
}

export default PlayerDisplay;